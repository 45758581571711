import React from 'react'
import { useParams } from 'react-router-dom';
import { useShopify } from '../hooks';
import { Grid } from '@material-ui/core';
import Footer from './Footer';
import Product from './Product';
import { Button } from './ButtonElement';
import { Link } from 'react-router-dom';
import Cart from './Cart';



const Placeholder = () => {
	const getNumberOfItems = () => {
		const width = window.innerWidth;

		if (width < 960) return 4;
		if (width < 1280) return 6;
		return 8;
	};

	const numberedArray = Array.from(Array(getNumberOfItems()).keys());

	return numberedArray.map((number) => (
		<Grid item xs={6} md={4} lg={3} key={number}>
			<div className="loading_block" />
		</Grid>
	));
};

const ProductLanding = (props) => {
	const { products } = useShopify();
	const params = useParams();

	return (
		<>
			<div>
				<div className="floating">
					<img src="/././Assets/logo.png" width="85" height="85" alt="Timmy2Cans Merch Store" />
				</div>
				<div className="App__header">

					<div className="carousel-image">
						<img src="/././Assets/banner.png" alt="Timmy2Cans Merch Store" />
					</div>

				</div>

				<div className="App__title">
					<h1 className="App__title-text">The Official Timmy2Cans Clothing Store</h1>
				</div>
				<div className="product-sort-buttons">
					<Link style={{ textDecoration: 'none' }} to="/">
						<Button className="sort-buttons">View All</Button>
					</Link>
				</div>
			</div>
			<div className="Products-wrapper">
				<div className="Products-wrapper__inner" style={{ maxWidth: '500px' }}>
					{products && products.length > 0 ? (
						products.filter(product => product.handle.includes(params.id)).map((shirt, i) => (
							<Product product={shirt} history={props.history} />
						))
					) : (
						<Placeholder />
					)}
				</div>
			</div >

			<Cart />
			<Footer />
		</>
	)
}

export default ProductLanding;